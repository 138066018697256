<template>
  <div class="mt-5">
    <v-data-table
      :headers="headers"
      :items="sounds">
      <template #[`item.createTime`]="{ item }">
        {{ item.createTime | formatDate }}
      </template>
      <template #[`item.isDefault`]="{ item }">
        <v-icon small
                :style="{ color: item.isDefault === true ? '#689F38' : '#E8EAF6' }">
          mdi-checkbox-marked-outline
        </v-icon>
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon
                   @click="getSound(item)"
                   v-on="on">
              <v-icon small
                      color="grey lighten-1">
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="openFormUpdateSound"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Department</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="soundData.name"
              label="Department name"
              outlined
              required />

            <v-switch
              v-model="soundData.isDefault"
              label="Default"></v-switch>

            <v-checkbox
              v-model="soundData.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="updateSound">
            update
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormUpdateSound = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'soundList',
  props: {
    sounds: {
      type: Array
    }
  },
  data() {
    return {
      openFormUpdateSound: false,
      editedIndex: -1,
      headers: [
        {
          text: 'Sound Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Default Status',
          align: 'center',
          value: 'isDefault'
        },
        {
          text: 'Create Date',
          align: 'center',
          sortable: true,
          value: 'createTime'
        },
        {
          align: 'start',
          sortable: true,
          value: 'action'
        },
      ],
      soundData: {
        _id: '',
        name: '',
        activated: '',
      }
    };
  },
  methods: {
    async getSound(item) {
      this.editedIndex = this.sounds.indexOf(item);

      this.soundData = { ...item };
      this.openFormUpdateSound = true;
    },

    async updateSound() {
      await this.$store.dispatch('masterData/UPDATE_SOUND', this.soundData);
      this.openFormUpdateSound = false;
    }
  }
};
</script>
